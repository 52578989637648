export const OrderStatusEnum = {
  Waiting_Quote: 0,
  Quoted: 1,
  Accepted: 2,
  Rejected: 3,
  Processing: 4,
  Landing: 5,
  Delivery: 6,
  Completed: 7,
};

export const OrderStatusParse = {
  [OrderStatusEnum.Waiting_Quote]: "Chờ báo giá",
  [OrderStatusEnum.Quoted]: "Đã báo giá",
  [OrderStatusEnum.Accepted]: "Đang đặt hàng",
  [OrderStatusEnum.Rejected]: "Đã huỷ",
  [OrderStatusEnum.Processing]: "Nhập hàng",
  [OrderStatusEnum.Landing]: "Nhập Kho",
  [OrderStatusEnum.Delivery]: "Vận chuyển",
  [OrderStatusEnum.Completed]: "Hoàn Tất",
};

export const OrderStatusUpdateParse = {
  [OrderStatusEnum.Waiting_Quote]: "Chờ báo giá",
  [OrderStatusEnum.Quoted]: "Báo giá",
  [OrderStatusEnum.Accepted]: "Đang đặt hàng",
  [OrderStatusEnum.Rejected]: "Huỷ",
  [OrderStatusEnum.Processing]: "Nhập hàng",
  [OrderStatusEnum.Landing]: "Nhập Kho",
  [OrderStatusEnum.Delivery]: "Vận chuyển",
  [OrderStatusEnum.Completed]: "Hoàn thành",
};

export const PaidEnum = {
  Non: 0,
  Paid: 1,
};

export const PaidEnumParse = {
  [PaidEnum.Non]: "Chưa thanh toán",
  [PaidEnum.Paid]: "Đã thanh toán",
};
